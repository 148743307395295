h1 {
    font-size: 1.5rem;
    color: #f5835d;
    margin: 0 0 4px 15px !important;
  }
  
  .App-header {
    background-color: #282c35;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  /*panels*/
  .interactionarea{
    display: flex;
    justify-content: space-between;
  }
  
  .eqpdropdown{
    left: 0;
    min-width: 30%;
  }
  
  .eqpdatapicker{
    right: 0;
    min-width: 30%
    
  }
  .dataarea{
    padding-top: 1%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .panel{
    min-width: 80%;
  }
  
  .timeBtn{
    position: relative;
    margin-left: 70%;
    padding: 3%;
  }
  
  footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: #fff !important;
    padding: 15px !important;
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%);
    background-color: #fff !important;
  }
  
  .header-content {
    display: flex;
    align-items: center;
  }
  
  .header-image {
    max-height: 42px;
  }
  
  .ant-modal {
    top: 10vh !important;
  }
  
  .modal-container {
    height: 65vh;
  }
  
  .kpi-card {
    min-width: 175px;
  }
  
  .black{
    color: black; 
  }
  .red {
     color :white;
     background-color: #f5835d !important; 
  }
  .red:hover > td {
    background-color: #f7a285 !important; 
  }
  
  td.ant-table-column-sort {
    color :black;
    background: #fafafa;
  }
  
  .ant-collapse-content-box {
    background-color: #f5f5f5 !important;
  }
  
  .ant-collapse-header {
    background-color: white !important;
    color: #f5835d !important;
  }
  
  .ant-descriptions-item-label {
    margin-left: 8px !important;
    margin-bottom: 0px !important;
  }
  
  .ant-descriptions-item {
    padding-bottom: 0px !important;
  }

  .ant-layout-content {
    padding: 0 0 0 0 !important;
  }

  .ant-layout-content.site-layout-background {
    padding: 15px 0 0 0 !important;
  }

  .ant-descriptions-item-label.area-blue {
    background-color: blue !important;
    border-top-left-radius: 8px !important;
  }

  .ant-descriptions-item-label.area-green {
    background-color: green !important;
  }

  .ant-descriptions-item-label.area-yellow {
    background-color: yellow !important;
  }

  .ant-descriptions-item-label.area-red {
    background-color: red !important;
    border-bottom-left-radius: 8px !important;
  }

  

  /* .css-1978mzo-canvas-content {
    padding: 0px !important;
  } */