footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #fff !important;
  padding: 15px !important;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%);
  background-color: #fff !important;
  z-index: 100;
}

.fixed-sider {
  position: fixed !important;
  top: 64px;
  height: 100%
}

.header-content {
  display: flex;
  align-items: center;
}

.header-image {
  max-height: 42px;
}

.header-image-app {
  max-height: 42px;
  margin-left: 16px;
}

.header-image-home {
  display: flex;
  align-items: center;
}

h1 {
  font-size: 1.5rem;
  color: #f5835d;
  margin: 0 0 4px 15px !important;
}

